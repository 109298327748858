<template>
  <vs-progress indeterminate color="primary">primary</vs-progress>
</template>
<script>
export default {
  name: 'Dashboard',

  data() {
    return {
      count: 0,
      max: 5
    }
  },

  created() {
    this.redirectToFirstAvailablePage();
  },

  methods: {
    redirectToFirstAvailablePage() {
      let menu = this.$store.getters['menuModule/getMenu'];
      if (menu[0]) {
        setTimeout(() => {
          if (menu[0].link && menu[0].link.trim() !== '') {
            this.$router.push(menu[0].link);
          } else if (menu[0].childs) {
            let firstChild = menu[0].childs[0];
            this.$router.push(firstChild.link);
          }
        }, 100);
      } else if (this.count <= this.max) {
        this.count++;
        setTimeout(() => {
          this.redirectToFirstAvailablePage();
        }, 100);
      }
    }
  }
}
</script>